<template>
  <!-- 文件生成与下载组件 -->
  <div class="fileUpdate" ref="fileUpdate">
    <el-input
      v-bind="$attrs"
      v-on="$listeners"
      disabled
      style="flex:1"
    ></el-input>
    <div class="row fl-16">
      <IconButton
        content="预览"
        icon="iconfont iconyulan"
        @click="preview"
        v-if="fileId && fileId !== '0'"
      ></IconButton>
      <!-- <IconButton content='生成' icon='iconfont iconshengcheng' @click="generate" v-if="$attrs.type!=='info'"></IconButton> -->
      <IconButton
        v-if="$attrs.type !== 'info'"
        :content="fileId && fileId !== '0' ? '重新上传' : '上传'"
        @click="() => $refs.uploadRef.click()"
        :icon="
          fileId && fileId !== '0'
            ? 'iconfont iconzhongxinshangchuan'
            : 'iconfont iconshangchuan'
        "
      ></IconButton>

      <IconButton
        content="下载"
        icon="iconfont iconxiazai"
        @click="downFile"
        v-if="fileId && fileId !== '0'"
      ></IconButton>

      <IconButton
        content="删除"
        icon="iconfont iconshanchu1"
        @click="delFile"
        v-if="fileId && fileId !== '0' && $attrs.type !== 'info'"
      ></IconButton>
      <el-upload
        action=""
        class="upload-demo"
        :http-request="uploadFile"
        :show-file-list="false"
        accept=".doc,.docx,.pdf,.xls,.xlsx,.jpg,.jpeg,.png,.bmp"
      >
        <div ref="uploadRef"></div>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { testFile } from '../utils/file-valid'
export default {
  name: 'fileUpdate',
  components: { IconButton },
  data () {
    return {
      value: ''
    }
  },
  props: {
    generateFileId: String
  },
  computed: {
    fileId: {
      get () {
        return this.generateFileId
      },
      set (val) {}
    }
  },
  watch: {
    // generateFileId (val) {
    //   console.log(val, 'generateFileId')
    //   this.fileId = val
    // }
  },

  methods: {
    // 删除
    delFile () {
      this.$emit('delFile', '')
    },
    // 预览
    preview () {
      this.$emit('preview', '')
    },
    // 生成
    generate () {
      this.$emit('generate', 'generate')
    },
    // 文件上传
    uploadFile (param) {
      if (!testFile(param)) {
        return
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then(res => {
        if (res.code === '0') {
          this.success('文件上传成功')
          this.value = res.data.fileName

          this.$emit('input', res.data.fileName)
          this.$emit('upload', {
            fileId: res.data.keyId,
            fileName: res.data.fileName
          })
        } else {
          this.error('文件上传失败')
        }
      })
    },
    // 文件下载
    downFile () {
      if (this.fileId) {
        const params = { keyId: this.fileId }
        downAPi
          .download(params)
          .then(res => {
            downFile(res)
          })
          .catch(e => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.fileUpdate {
  display: flex;
  width: 100%;
}
.row {
  display: flex;
}
</style>
