export const testFile = (param) => {
  const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
  if (EmojiReg.test(param.file.name)) {
    this.warning('文件名不能存在Emoji')
    return false
  }
  if (param.file.name.length > 80) {
    return this.warning('文件名称小于80字符')
  }
  const maxSize = 1024 * 10
  const fileSize = Math.round(param.file.size / 1024)
  if (fileSize - maxSize >= 0) {
    return this.warning('文件需小于10M')
  }
  const arr = param.file.name.split('.')
  const suffix = (arr[arr.length - 1]).toLowerCase()
  const lite = [
    'docx', 'pdf', 'doc', 'xls', 'xlsx', 'jpg', 'jpeg', 'png', 'bmp']
  const flag = lite.some((val) => {
    return suffix === val
  })
  if (!flag) {
    this.warning('请上传Doc、Pdf、Excel或图片格式文件')
    return false
  }
  return true
}
